.form-control{
    border: none;
    border-radius: 2px !important;
    background-color: #f0f0f0 !important;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300 !important;
}
.form-control:focus {
    border-color: #f0f0f0;
    box-shadow: none !important;
}
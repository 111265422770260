.card {
    width: 100% !important;
    text-decoration: none;
    border: none !important;
    border-radius: 0% !important;
    box-shadow: 0 0 4px 0 rgba(57, 78, 234, 0.1)
}
.card:hover {
    border-radius: 0% !important;
    text-decoration: none;
    box-shadow: 0 0 12px 0 rgba(57, 78, 234, 0.1)
}

.image-box {
    position: relative;
    margin: auto;
    overflow: hidden;
    /*width: 100% !important;*/
}
.image-box img {
    width: 100% !important;
    transition: all 0.3s;
    display: block;
    height: auto;
    transform: scale(1);
}
.image-box:hover img {
    transform: scale(1.1);
}
.accordionMobile {
    background-color: #ffffff;
    color: #444;
    cursor: pointer;
    padding: 7px;
    width: 100%;
    border: none;
    font-size: 14px;
    text-align: left;
    outline: none;
    font-weight: 300;
    font-family: 'Roboto Condensed', sans-serif;
    transition: 0.4s;
    overflow: paged-y;
    box-shadow: 0 0 2px 0 rgba(57, 78, 234, 0.1)
}
.active, .accordionMobile:hover {
    color: #ffffff;
    font-weight: 400;
    background-color: #F59315;
}
.accordionMobile:after {
    content: '\276F';
    color: #f3f3f3;
    float: right;
    margin-left: 5px;
}
.active:after,hover {
    color: #ffffff;
    content: "\276E";
}
.panelMobile {
    padding: 0 10px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}
.accordionMenuDivMobile{
    width: 100%;
    direction:rtl;
    font-family: 'Roboto Condensed', sans-serif !important;
    font-weight: 300 !important;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #e9e9e9 #ffffff;
}
.accordionMenuDivInsideMobile{
    direction:ltr;
}
.accordionMenuIconMobile{
    width: 20px;
    height: auto;
}
.accordionItemMobile{
    color: #444;
    text-decoration: none;
}
.accordionItemMobile:hover{
    text-decoration: none;
}
@media (max-width: 374.98px) {
    .section-title{
        font-size: 16px;
    }
    .section-sub-title{
        font-size: 13px;
    }
    .product-name-on-card{
        font-size: 14px;
    }
    .product-price-on-card{
        font-size: 13px;
    }
    .Mobile{

    }
    .Desktop{
        display: none;
    }
    .slider-img{
        height: 180px;
    }
    .TopSection{
        margin-top: 50px;
    }

    body {
        font-size: 14px;
    }
}

@media (min-width: 375.98px) and (max-width: 575.98px){
    .section-title{
        font-size: 16px;
    }
    .section-sub-title{
        font-size: 13px;
    }
    .product-name-on-card{
        font-size: 14px;
    }
    .product-price-on-card{
        font-size: 13px;
    }
    .slider-img{
        height: 180px;
    }
    .Mobile{

    }
    .Desktop{
        display: none;
    }
    .TopSection{
        margin-top: 50px;
    }
    body {
        font-size: 14px;
    }
}


@media (min-width: 576px) and (max-width: 767.98px) {

    .section-title{
        font-size: 16px;
    }
    .section-sub-title{
        font-size: 13px;
    }
    .product-name-on-card{
        font-size: 14px;
    }
    .product-price-on-card{
        font-size: 13px;
    }
    .Mobile{

    }
    .Desktop{
        display: none;
    }

    .slider-img{
        height: 180px;
    }
    .TopSection{
        margin-top: 63px;
    }

    body {
        font-size: 14px;
    }

}


@media (min-width: 768px) and (max-width: 991.98px) {
    .section-sub-title{
        font-size: 13px;
    }
    .Mobile{
        display: none;
    }
    .Desktop{
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {

    .Mobile{
        display: none;
    }
    .Desktop{
    }
}


@media (min-width: 1200px) {

    .Mobile{
        display: none;
    }
    .Desktop{
    }

}




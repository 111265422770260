html {
    height: auto !important;
}
body {
    background-color: #FAFDFB !important;
    font-size: 15px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;
    color: #212121;
    position: initial !important;
    min-height: initial !important;
    top: auto !important;
}

.goog-te-banner-frame {
    top:initial;
    bottom: 0;
    display: none;
}



.BetweenTwoSection{
    margin-top: 20px;
    margin-bottom: 20px;
}

.TopSection{
    margin-top: 70px;
}

.Link{
    text-decoration: none !important;
}

.Link:hover{
    text-decoration: none !important;
}

.ph-item{
    border: none !important;
}
a{
    text-decoration: none !important;
}
a:hover{
    text-decoration: none !important;
}
.contactFormBtn{
    width: 100%;
}
.cart-product-img{
    width: 100%;
    height: 100%;
}

.cart-card{
    width: 100% !important;
    text-decoration: none;
    border-radius: 0% !important;
    box-shadow: none;
    border: 0.5px solid #f0f0f0 !important;
}

.cart-card:hover{
    box-shadow: none;
}